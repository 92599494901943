<!--
 * @Author: wxb
 * @Date: 2021-03-25 16:02:03
 * @LastEditTime: 2021-07-24 09:45:40
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\components\common\projectTimeline.vue
-->
<template>
  <div>
    <el-timeline>
      <el-timeline-item :timestamp="item.releasetime"
                        placement="top"
                        v-for="(item,index) in timelineData"
                        :key="index">
        <el-card>
          <div class="project-schedule-item-title">
            <a @click="handleClickNotice(item.id)">{{item.title}}</a>
          </div>
          <el-tag>{{item.noticeTypeName}}</el-tag>
        </el-card>
      </el-timeline-item>
    </el-timeline>
    <el-empty v-show="timelineData.length===0"
              description="未找到相关数据"></el-empty>
  </div>
</template>

<script>
import { getPreliminarydesign } from '@/api/project'
export default {
  name: 'projectTimeline',
  data() {
    return {
      timelineData: []
    }
  },
  methods: {
    initPage(params) {
      getPreliminarydesign(params).then(res => {
        if (res.ok) {
          this.timelineData = res.lst
        }
      }).catch(err => {
        console.log(err)
      })
    },
    handleClickNotice(id) {
      const routeData = this.$router.resolve({
        name: 'noticeDetail',
        params: {
          id
        }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.project-schedule-item-title {
  padding: 10px 0px;
  a {
    cursor: pointer;
    &:hover {
      color: #398ce2;
    }
  }
}
</style>
