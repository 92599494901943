<!--
 * @Author: wxb
 * @Date: 2021-03-25 16:06:28
 * @LastEditTime: 2021-07-01 15:45:47
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\components\common\projectTree.vue
-->
<template>
  <el-tree :data="data"
           :props="defaultProps"
           highlight-current
           default-expand-all
           node-key="projectId"
           ref="tree"
           :render-content="renderContent"
           :expand-on-click-node="false"
           @node-click="handleNodeClick"></el-tree>
</template>

<script>
export default {
  name: 'projectTree',
  data() {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: 'projectName'
      },
      params: {}
    }
  },
  methods: {
    handleNodeClick(data) {
      this.$emit('nodeclick4project', data.projectId)
    },
    initPage(params) {
      this.params = params
      this.data = params.projectTree
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(params.id)
      })
    },
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span class="span-ellipsis" title={node.label}>{node.label}</span>
        </span>)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .span-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
