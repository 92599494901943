<!--
 * @Author: wxb
 * @Date: 2021-03-25 15:50:19
 * @LastEditTime: 2021-07-01 17:54:13
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\components\common\projectReply.vue
-->
<template>
  <div class="containner">
    <el-table :data="tableData"
              border
              style="width: 100%">
      <el-table-column prop="xmpfgspfmc"
                       show-overflow-tooltip
                       label="批复名称">
      </el-table-column>
      <el-table-column prop="xmpfgsswdw"
                       show-overflow-tooltip
                       label="受文单位">
      </el-table-column>
      <el-table-column prop="xmpfgspfbh"
                       show-overflow-tooltip
                       label="批复编号">
      </el-table-column>
      <el-table-column prop="xmpfgskindsofficialname"
                       show-overflow-tooltip
                       label="批复类型">
      </el-table-column>
      <el-table-column prop="xmpfgspfsj"
                       show-overflow-tooltip
                       label="批复时间">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getProjectapproval } from '@/api/project'
export default {
  name: 'projectReply',
  data() {
    return {
      tableData: []
    }
  },
  methods: {
    initPage(params) {
      getProjectapproval(params).then(res => {
        if (res.ok) {
          this.tableData = res.lst
        }
      }).catch(err => { console.log(err) })
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
