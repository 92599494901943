<!--
 * @Author: wxb
 * @Date: 2021-06-22 16:55:10
 * @LastEditTime: 2021-07-30 11:30:49
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Project\builtProInfo.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='3'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/project'}">找项目</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/builtPro'}">已建项目</el-breadcrumb-item>
        <el-breadcrumb-item>已建项目详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="project"
           v-show="canSee">
        <div class="notice-panel">
          <div class="notice-title">{{projectDetail.projectName}}</div>
          <div class="notice-panel-info">
            <div class="notice-follow"
                 :class="{followed:projectDetail.action==='add' }"
                 title="关注项目实时接收项目最新公告"
                 @click="handleFollow">
              <svg class="icon"
                   aria-hidden="true">
                <use :xlink:href="projectDetail.action | formatIcon"></use>
              </svg>关注项目
            </div>
          </div>
        </div>
        <div class="panel">
          <div class="panel-title">基本信息</div>
          <div class="panel-content">
            <table class="table table-border table-bordered">
              <tr>
                <td class="text-r notice-content-title"
                    width="150">项目名称</td>
                <td class="notice-content-info">{{projectDetail.projectName}} </td>
              </tr>
              <tr>
                <td class="text-r notice-content-title">项目类型</td>
                <td class="notice-content-info">{{projectDetail.projectTypeName}} </td>
              </tr>
              <tr>
                <td class="text-r notice-content-title">招标单位</td>
                <td class="notice-content-info">{{projectDetail.biddingUnit}} </td>
              </tr>
              <tr>
                <td class="text-r notice-content-title">招标代理单位</td>
                <td class="notice-content-info">{{projectDetail.biddingAgency}} </td>
              </tr>
              <tr>
                <td class="text-r notice-content-title">招标代理单位联系人</td>
                <td class="notice-content-info">{{projectDetail.biddingAgencyContacts}} </td>
              </tr>
              <tr>
                <td class="text-r notice-content-title">招标代理单位联系方式</td>
                <td class="notice-content-info"> {{projectDetail.biddingAgencyContactInformation}}</td>
              </tr>
              <tr>
                <td class="text-r notice-content-title">预算金额</td>
                <td class="notice-content-info"> {{projectDetail.budget}}{{projectDetail.budgetAmountunit}}</td>
              </tr>
              <tr>
                <td class="text-r notice-content-title">限价金额</td>
                <td class="notice-content-info"> {{projectDetail.fixedPrice}}{{projectDetail.fixedPriceAmountunit}}</td>
              </tr>
              <tr>
                <td class="text-r notice-content-title">中标单位</td>
                <td class="notice-content-info"> {{projectDetail.bidWinner}}</td>
              </tr>
              <tr>
                <td class="text-r notice-content-title">中标金额</td>
                <td class="notice-content-info"> {{projectDetail.bidAmount}}{{projectDetail.bidWinningAmountunit}}</td>
              </tr>
              <tr>
                <td class="text-r notice-content-title">需求附件</td>
                <td class="notice-content-info">
                  <div class="file-list"
                       v-if="projectDetail.demandEnclosure">
                    <div class="file-item"
                         v-for="(item,index) in projectDetail.demandEnclosure"
                         :key="index">
                      <el-link :underline="false"
                               type="primary"
                               @click="handleClickItem(item.id)">{{item.name}}</el-link>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-r notice-content-title">招标附件</td>
                <td class="notice-content-info">
                  <div class="file-list"
                       v-if="projectDetail.biddingEnclosure">
                    <div class="file-item"
                         v-for="(item,index) in projectDetail.biddingEnclosure"
                         :key="index">
                      <el-link :underline="false"
                               type="primary"
                               @click="handleClickItem(item.id)">{{item.name}}</el-link>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-r notice-content-title">中标附件</td>
                <td class="notice-content-info">
                  <div class="file-list"
                       v-if="projectDetail.bidEnclosure">
                    <div class="file-item"
                         v-for="(item,index) in projectDetail.bidEnclosure"
                         :key="index">
                      <el-link :underline="false"
                               type="primary"
                               @click="handleClickItem(item.id)">{{item.name}}</el-link>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="panel">
          <div class="panel-title">项目历程</div>
          <div class="panel-content">
            <div class="project-course">
              <div class="project-course-tree">
                <projectTree ref="projecttree"
                             @nodeclick4project="nodeclick4project"></projectTree>
              </div>
              <div class="project-course-content">
                <el-tabs tab-position="left"
                         v-model="activeName"
                         @tab-click="handleClickTab">
                  <el-tab-pane label="项目备案/立项批复"
                               name="项目备案/立项批复">
                    <div class="project-course-item">
                      <div class="project-course-item-title">项目审批</div>
                      <div class="project-course-item-content">
                        <projectApproval ref="record"></projectApproval>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="项目建议书"
                               name="项目建议书">
                    <div class="project-course-item">
                      <div class="project-course-item-title">项目审批</div>
                      <div class="project-course-item-content">
                        <projectApproval ref="proposal"></projectApproval>
                      </div>
                    </div>

                    <div class="project-course-item">
                      <div class="project-course-item-title">项目批复</div>
                      <div class="project-course-item-content">
                        <projectReply ref="proposal4pf"></projectReply>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="初步设计"
                               name="初步设计">
                    <projectTimeline ref="design"></projectTimeline>
                  </el-tab-pane>
                  <el-tab-pane label="初步设计批复"
                               name="初步设计批复">
                    <div class="project-course-item">
                      <div class="project-course-item-title">项目批复</div>
                      <div class="project-course-item-content">
                        <projectReply ref="replay4cbsj"></projectReply>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="咨询造价"
                               name="咨询造价">
                    <projectTimeline ref="cost"></projectTimeline>
                  </el-tab-pane>
                  <el-tab-pane label="建设资金申请批复"
                               name="建设资金申请批复">
                    <div class="project-course-item">
                      <div class="project-course-item-title">项目批复</div>
                      <div class="project-course-item-content">
                        <projectReply ref="reply4jszj"></projectReply>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="项目实施"
                               name="项目实施">
                    <div class="project-course-itemtree">
                      <projectTimeline ref="timeline4xmss"></projectTimeline>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="竣工验收"
                               name="竣工验收">
                    <div class="project-course-itemtree">
                      <projectTimeline ref="timeline4jgys"></projectTimeline>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>

          </div>
        </div>
        <div class="panel">
          <div class="panel-title">项目列表</div>
          <div class="panel-content">
            <el-table :data="projectLst"
                      style="width: 100%"
                      border
                      fit>
              <el-table-column prop="projectName"
                               show-overflow-tooltip
                               label="项目名称">
              </el-table-column>
              <el-table-column prop="budget"
                               label="预算金额"
                               header-align="left"
                               align="right"
                               width="120">
                <template slot-scope="scope">
                  <div>{{scope.row.budget}}{{scope.row.budgetAmountunit}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="fixedPrice"
                               label="限价金额"
                               width="120"
                               header-align="left"
                               align="right">
                <template slot-scope="scope">
                  <div>{{scope.row.fixedPrice}}{{scope.row.fixedPriceAmountunit}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="bidAmount"
                               label="中标金额"
                               header-align="left"
                               align="right"
                               width="120">
                <template slot-scope="scope">
                  <div>{{scope.row.bidAmount}}{{scope.row.bidWinningAmountunit}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="bidWinner"
                               label="中标单位"
                               show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="biddingUnit"
                               show-overflow-tooltip
                               label="招标单位">
              </el-table-column>
              <el-table-column prop="biddingAgency"
                               show-overflow-tooltip
                               label="招标代理单位">
              </el-table-column>
              <el-table-column prop="biddingAgencyContacts"
                               show-overflow-tooltip
                               label="招标代理单位联系人">
              </el-table-column>
              <el-table-column prop="biddingAgencyContactInformation"
                               show-overflow-tooltip
                               label="招标代理单位联系方式">
              </el-table-column>

            </el-table>
          </div>
        </div>

      </div>
      <viporlogin bgStr="5"
                  :type="viporlogin"
                  v-show="!canSee"></viporlogin>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import projectApproval from '@/components/common/projectApproval'
import projectReply from '@/components/common/projectReply'
import projectTimeline from '@/components/common/projectTimeline'
import projectTree from '@/components/common/projectTree'
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
import { getProjectDetails, getprojectImplementation } from '@/api/project'
import { follow } from '@/api/follow'
export default {
  name: 'builtProInfo',
  components: {
    headerNav,
    footerNav,
    projectApproval,
    projectReply,
    projectTimeline,
    projectTree,
    viporlogin
  },
  data() {
    return {
      viporlogin: 'login',
      canSee: true,
      projectId: '',
      projectLst: [],
      rootProjectId: '',
      projectTree: {},
      projectDetail: {},
      activeName: '项目备案/立项批复',
      nodeprojectid: ''
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  methods: {
    handleClickTab(tab) {
      const label = tab.label
      switch (label) {
        case '项目备案/立项批复':
          this.$nextTick(() => {
            const params4record = {
              projectId: this.nodeprojectid,
              projectPhase: '1'
            }
            this.$refs.record.initPage(params4record)
          })
          break
        case '项目建议书':
          this.$nextTick(() => {
            const params = {
              projectId: this.nodeprojectid,
              projectPhase: '2'
            }
            this.$refs.proposal.initPage(params)

            const params4pf = {
              projectId: this.nodeprojectid,
              approvaltype: '1'
            }
            this.$refs.proposal4pf.initPage(params4pf)
          })

          break
        case '初步设计':
          this.$nextTick(() => {
            const params = {
              projectId: this.nodeprojectid,
              projectPhase: '4'
            }
            this.$refs.design.initPage(params)
          })

          break
        case '初步设计批复':
          this.$nextTick(() => {
            const params4pf = {
              projectId: this.nodeprojectid,
              approvaltype: '3'
            }
            this.$refs.replay4cbsj.initPage(params4pf)
          })

          break
        case '咨询造价':
          this.$nextTick(() => {
            const params = {
              projectId: this.nodeprojectid,
              projectPhase: '5'
            }
            this.$refs.cost.initPage(params)
          })

          break
        case '建设资金申请批复':
          this.$nextTick(() => {
            const params4pf = {
              projectId: this.nodeprojectid,
              approvaltype: '5'
            }
            this.$refs.reply4jszj.initPage(params4pf)
          })

          break
        case '项目实施':
          this.$nextTick(() => {
            const params = {
              projectId: this.nodeprojectid,
              projectPhase: '6'
            }
            this.$refs.timeline4xmss.initPage(params)
          })

          break
        case '竣工验收':
          this.$nextTick(() => {
            const params = {
              projectId: this.nodeprojectid,
              projectPhase: '7'
            }
            this.$refs.timeline4jgys.initPage(params)
          })

          break
        default:
          break
      }
    },
    // 树形项目表点击
    nodeclick4project(id) {
      this.nodeprojectid = id
      this.$nextTick(() => {
        switch (this.activeName) {
          case '项目备案/立项批复':
            this.$nextTick(() => {
              const params4record = {
                projectId: this.nodeprojectid,
                projectPhase: '1'
              }
              this.$refs.record.initPage(params4record)
            })
            break
          case '项目建议书':
            this.$nextTick(() => {
              const params = {
                projectId: this.nodeprojectid,
                projectPhase: '2'
              }
              this.$refs.proposal.initPage(params)

              const params4pf = {
                projectId: this.nodeprojectid,
                approvaltype: '1'
              }
              this.$refs.proposal4pf.initPage(params4pf)
            })

            break
          case '初步设计':
            this.$nextTick(() => {
              const params = {
                projectId: this.nodeprojectid,
                projectPhase: '4'
              }
              this.$refs.design.initPage(params)
            })

            break
          case '初步设计批复':
            this.$nextTick(() => {
              const params4pf = {
                projectId: this.nodeprojectid,
                approvaltype: '3'
              }
              this.$refs.replay4cbsj.initPage(params4pf)
            })

            break
          case '咨询造价':
            this.$nextTick(() => {
              const params = {
                projectId: this.nodeprojectid,
                projectPhase: '5'
              }
              this.$refs.cost.initPage(params)
            })

            break
          case '建设资金申请批复':
            this.$nextTick(() => {
              const params4pf = {
                projectId: this.nodeprojectid,
                approvaltype: '5'
              }
              this.$refs.reply4jszj.initPage(params4pf)
            })

            break
          case '项目实施':
            this.$nextTick(() => {
              const params = {
                projectId: this.nodeprojectid,
                projectPhase: '6'
              }
              this.$refs.timeline4xmss.initPage(params)
            })

            break
          case '竣工验收':
            this.$nextTick(() => {
              const params = {
                projectId: this.nodeprojectid,
                projectPhase: '7'
              }
              this.$refs.timeline4jgys.initPage(params)
            })

            break
          default:
            break
        }
      })
    },
    // 鉴权
    authCheck() {
      const authObj = authenticate('builtProDetail', this.memberType)
      if (authObj.flag) {
        // 初始化页面
        this.initPage()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    initPage() {
      const params = {
        id: this.projectId
      }
      getProjectDetails(params).then(res => {
        if (res.ok) {
          this.projectLst = res.lst
          this.rootProjectId = res.rootProjectId
          const idx = res.lst.findIndex(value => value.projectId === this.projectId)
          this.projectDetail = res.lst[idx]
          this.projectDetail.action = res.action
          getprojectImplementation({ rootProjectId: this.rootProjectId }).then(res => {
            if (res.ok) {
              this.projectTree = res.tree
              this.nodeprojectid = this.projectId
              // 加载树形项目
              const params = {
                id: this.projectId,
                projectTree: [this.projectTree]
              }
              this.$refs.projecttree.initPage(params)
              // 加载项目实施
              this.$nextTick(() => {
                this.activeName = '项目实施'
                const params = {
                  projectId: this.projectId,
                  projectPhase: '6'
                }
                this.$refs.timeline4xmss.initPage(params)
              })
            }
          }).catch(err => {
            console.log(err)
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 项目关注
    handleFollow() {
      const params = {
        concernsId: this.projectId,
        concernsType: 'project',
        concernsTitle: this.projectDetail.projectName
      }
      if (this.projectDetail.action === 'cancle') {
        params.relationProjectId = this.projectId
        params.relationProjectName = this.projectDetail.projectName
        params.action = 'add'
        this.handleFollowEvent(params)
      } else if (this.projectDetail.action === 'add') {
        params.action = 'cancle'
        this.$confirm('确认取消项目关注?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleFollowEvent(params)
        })
      }
    },
    handleFollowEvent(params) {
      follow(params).then(res => {
        if (res.ok) {
          this.projectDetail.action = params.action
          this.$forceUpdate()
          if (params.action === 'add') {
            this.$message({
              message: '关注成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: '取消关注成功',
              type: 'success'
            })
          }
        } else {
          this.$message({
            message: res,
            type: 'warning'
          })
        }
      }).catch(err => { console.log(err) })
    },
    // 处理跳转到附件预览
    handleClickItem(filekey) {
      const routeData = this.$router.resolve({
        name: 'preview',
        params: {
          filekey
        }
      })
      window.open(routeData.href, '_blank')
    }
  },
  mounted() {
    const reqParams = this.$route.params
    this.projectId = reqParams.id
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  },
  filters: {
    formatIcon(action) {
      if (action === 'add') {
        return '#icon-shoucang2'
      } else {
        return '#icon-shoucang1'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.panel {
  margin-top: 2rem;
  padding: 1rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  &-title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 16px;
    font-weight: 500;
  }

  &-content {
    padding: 1rem 0;
  }
}

.notice {
  &-title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  &-content-title {
    background: #f4f4ff;
  }
}

// 顶部关注
.notice {
  &-panel {
    border: 1px solid #eee;
    padding: 15px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    &-info {
      display: flex;
      width: 800px;
      margin: 10px auto;
      justify-content: flex-end;
      font-size: 14px;

      .notice-follow {
        cursor: pointer;

        &.followed {
          .icon {
            color: #ec2929;
          }
        }
      }
    }
  }
}

// 项目历程
.project-course {
  display: flex;
  &-tree {
    width: 60rem;
  }

  &-content {
    flex: 1;
  }

  &-item {
    border: 1px solid #eee;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    width: 100%;
    &-title {
      font-size: 14px;
      padding: 8px 10px;
      border-bottom: 1px solid #ddd;
    }

    &-content {
      padding: 5px;
    }
  }
}
</style>
