<!--
 * @Author: wxb
 * @Date: 2021-03-25 15:46:01
 * @LastEditTime: 2021-07-01 17:53:46
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\components\common\projectApproval.vue
-->
<template>
  <div class="containner">
    <el-table :data="tableData"
              border
              style="width: 100%">
      <el-table-column prop="prebuildprospsx"
                       show-overflow-tooltip
                       label="审批事项">
      </el-table-column>
      <el-table-column prop="prebuildprospbm"
                       show-overflow-tooltip
                       label="审批部门">
      </el-table-column>
      <el-table-column prop="prebuildprosprq"
                       show-overflow-tooltip
                       label="审批日期">
      </el-table-column>
      <el-table-column prop="prebuildprospjg"
                       show-overflow-tooltip
                       label="审批结果">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getProjectfiling } from '@/api/project'
export default {
  name: 'projectApproval',
  data() {
    return {
      tableData: []
    }
  },
  methods: {
    initPage(params) {
      getProjectfiling(params).then(res => {
        if (res.ok) {
          this.tableData = res.lst
        }
      }).catch(err => { console.log(err) })
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
